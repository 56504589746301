import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';


function App() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    init()
  }, [visible])

  const init = () => {
    const timmer = setInterval(() => {
      getDetail()
    }, 5000)
    return () => clearInterval(timmer)
  }
  console.log('visible', visible)

  const getDetail = () => {
    console.log('visible1', visible)
    let temp = ''
    setVisible((pre) => {
      console.log('visible2', pre)
      temp = pre;
      return pre
    })
    console.log('temp', temp)
    // if (temp) {}
  }

// const [count, setCount] = useState(0);
//   useEffect(() => {
//     setInterval(() => {
//       setCount(count + 1);
//     }, 1500);
//   }, []);
//   console.log('count0', count);
//   useEffect(() => {
//     setInterval(() => {
//       console.log('count1', count);
//     }, 1500);
//   }, []);

//   useEffect(() => {
//     console.log('count2', count);
//   }, [count]);


  return (
    <div className="App">
      <header className="App-header">
        <button onClick={() => setVisible(!visible)}>setVisible</button>
        {visible && <img src={logo} className="App-logo" alt="logo" />}
        
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
